import React from 'react'

export default function Header() {
    const logo = 'https://i.imgur.com/kiVX15T.png'
    const companyName = 'My House'
    return (
        <>
        <h1 style={{position: 'absolute', fontSize: 10, opacity: 0, top: 0, left: 0}}>{companyName}</h1>
        <div className="nav-cont">
            <div className="nav-cont__left">
                <a href="/">
                    <img src={logo}></img>
                </a>
            </div>
        </div>
        </>
    )
}
