/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import MetaTags from 'react-meta-tags';
export default function Index() {
    const svgColor = '#5DFD73'
    const topGradient = '#00000'
    const bottomGradient = "#4B1362"
    const groupIds = ['63f84ddfaf90860002d72639','62d9a5d27863160033242286']

    const socialShareLink = 'https://i.imgur.com/tk4yOgN.jpeg'
    const metaDescription = 'Nightclub and Sports Bar'
    const metaTitle = 'My House'
    const website = 'https://www.myhouse.posh.vip'

    const [events, setEvents] = useState({});
    const [isEvents, setIsEvents] = useState(false);
    const [isEventsGroup1, setIsEventsGroup1] = useState(false);
    const [isEventsGroup2, setIsEventsGroup2] = useState(false);
    const [eventsGroup1, setEventsGroup1] = useState({});
    const [eventsGroup2, setEventsGroup2] = useState({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetch(`/poshAPI/${groupIds[0]}`)
            .then(async (res) => await res.json())
            .then(async (eventsAPI) => {
                if (eventsAPI.events) {
                    setEventsGroup1(eventsAPI.events);
                    setIsEventsGroup1(true);
                }
            })
        await fetch(`/poshAPI/${groupIds[1]}`)
            .then(async (res) => await res.json())  
            .then(async (eventsAPI) => {
                if (eventsAPI.events) {
                    setEventsGroup2(eventsAPI.events);
                    setIsEventsGroup2(true);
                }
            })
        }, []);

    useEffect(() => {
        if (isEventsGroup2 && isEventsGroup1) {
            const eventsArray = eventsGroup1.concat(eventsGroup2);
            const sortedEvents = eventsArray.sort((a, b) => {
                return new Date(a.start) - new Date(b.start);
            });
            setEvents(sortedEvents);
            setIsEvents(true);
        }
    }, [isEventsGroup2, isEventsGroup1]);

    return (
        <>
            <MetaTags>
                <title>My House</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="/assets/img/favicon.png" />
                <link href="/css/index.css" rel="stylesheet" />
                <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle}/>
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={socialShareLink} />
                <meta property="og:url" content={website} />
                <meta property="og:type" content="website" />
                <meta property="twitter:title" content={metaTitle} />
                <meta property="twitter:description" content={metaDescription} />
                <meta property="twitter:image" content={socialShareLink}/>
                <link href='https://fonts.googleapis.com/css?family=Raleway' rel='stylesheet'></link>
                <meta name="twitter:card" content="summary_large_image"></meta>
            </MetaTags>
            <svg viewBox="0 0 100 100" preserveAspectRatio="none" id="svg-A">
                <defs>
                    <linearGradient id="linearA" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="30%" stop-color={topGradient}></stop>
                        <stop offset="100%" stop-color={bottomGradient}></stop>
                    </linearGradient>
                </defs>
                <path d="M 0 25 L 100 20 L 100 100 L 0 100 L 0 0" style={{ fill: svgColor }}></path>
                <path d="M 0 30 L 100 20 L 100 100 L 0 100 L 0 0" style={{ fill: 'url(#linearA)' }}></path>
            </svg>
            <div style={{ position: 'relative', width: "100%", minHeight: "900px", display: 'flex', justifyContent: 'center' }}>
                <div className="body-inner-b">
                    <Header></Header>
                    {(isEvents && events && events.length > 0) &&
                        <>
                            <h2 style={{color: 'white'}}>UPCOMING EVENTS</h2>
                            {events && events.length > 0 && 
                                <div className="event-category-cont"> 
                                        <div className="desctop-scroll">
                                            {events.map(event => (
                                            event.status !== 'deleted' && 
                                                (<>
                                                    <a href={'https://posh.vip/e/' + event.url}>
                                                        <div className='ecc-e'>
                                                            <img src={event.flyer} className="ecc-e__flyer" />
                                                            <div className='ecc-e__filter'></div>
                                                            <div className='text-wrapper'>
                                                                <h1 className='event-date-small'>{event.displayDay}</h1>
                                                                <h1 className='event-date-large'>{event.dayOfMonth}</h1>
                                                            </div>
                                                            
                                                        </div>
                                                    </a>
                                                </>)
                                            ))}
                                        </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}
