import React from 'react'

export default function Footer() {
    const instagram = 'myhousecomo'
    const email = 'mailto:info@myhousecomo.com'
    
    return (
        <>
            <footer>
                <div className="footer__inner">
                    <div className="footer__inner__menus">
                        <div>
                            <h1>My House</h1>
                            <a href='https://posh.vip' target='__blank'>POSH</a>
                            <a href='https://posh.vip/tos'>Terms of User</a>
                            <a href='https://posh.vip/privacy'>Privacy Policy</a>
                        </div>
                        <div>
                            <h1>Contact</h1>
                            <a href={'https://www.instagram.com/'+instagram} target='__blank'>Instagram</a>
                            <a href={email}>Contact</a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
